<template>
  <carousel
    ref="carouselComponent"
    :wrapAround="true"
    :breakpoints="breakpoints"
  >
    <template v-if="isIndex">
      <slide v-for="item in data" :key="item.title">
        <div class="carousel__item">
          <CarouselCard
            :title="item.title"
            :body="item.body"
            :list="item?.list ? item.list : []"
          />
        </div>
      </slide>
    </template>
    <template v-else>
      <slide v-for="data in omnicanalData" :key="data.title">
        <div class="carousel__item">
          <PresenceOmnichanelCarouselCard
            :title="data.title"
            :sub-title="data.subTitle"
            :text="data.text"
          />
        </div>
      </slide>
    </template>
    <template #addons>
      <div class="position-relative w-100 my-5 pt-2">
        <button @click="prev" class="bg-ice btn-carousel l-side hover ms-4">
          <img
            src="../../assets/imgs/expand_left_1.png"
            alt="expand_left"
            class="img-fluid"
          />
        </button>
        <pagination />
        <button @click="next" class="bg-ice btn-carousel hover r-side">
          <img
            src="../../assets/imgs/expand_left_1.png"
            alt="expand_left"
            class="img-fluid"
          />
        </button>
      </div>
    </template>
  </carousel>
</template>

<script setup>
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination } from "vue3-carousel";

const breakpoints = {
  1400: {
    itemsToShow: 3,
  },

  1300: {
    itemsToShow: 2.5,
    snapAlign: "start",
  },
  768: {
    itemsToShow: 1.5,
    snapAlign: "start",
  },
  375: {
    itemsToShow: 1,
    snapAlign: "start",
  },
};

const props = defineProps({
  isIndex: {
    type: Boolean,
    default: true,
  },
});

const data = [
  {
    title: "Customer Experience",
    body: "A experiência do cliente (CX) desempenha um papel fundamental no sucesso de uma empresa. Clientes satisfeitos não apenas retornam para mais negócios, mas também se tornam defensores da marca, compartilhando suas experiências positivas com outros. Nós proporcionando soluções que transformam ideias em prática para melhorar a CX.",
  },
  {
    title: "Plataformas de Comunicação",
    body: "Somos especialistas nas mais poderosas, confiáveis e versáteis plataformas de comunicação do mercado, o que permite a composição de uma solução de atendimento omnichannel ao cliente, completa e eficiente, para potencializar o legado atual ou por meio de novas tecnologias.",
  },
  {
    title: "Engagement",
    body: "Incentivamos a construção de uma conexão emocional entre a sua marca e o cliente, estimulando sua participação ativa na geração de uma relação de confiança e fidelidade duradoura. Contribuimos na identificação das necessidades e desejos do cliente e construímos uma estratégia de envolvimento que ressoa com as suas emoções e valores.",
  },
  {
    title: "Conversational insights",
    body: "Proporcionamos muitos benefícios para a empresa, como:",
    list: [
      "Melhorar a experiência do cliente",
      "Aumentar o desempenho do agente",
      "Melhorar a eficiência operacional",
      "Proteger a reputação da empresa e garantir o cumprimento das conformidades para evitar perdas",
    ],
  },
  {
    title: "Consultoria de valor",
    body: "Fomentamos estratégias para otimizar os modelos de negócios atuais, identificamos oportunidades e desenhamos uma transição fluída no processo de transformação a partir de entregas estruturadas e de valor ao negócio. Inovamos processos para uma melhor experiência do cliente a partir de tecnologia, automação e análise inteligente.",
  },
];

const omnicanalData = [
  {
    title: "Crie jornadas de conversação personalizadas",
    subTitle: "",
    text: "Capacite o marketing, o produto e o suporte para organizar visualmente os fluxos de conversação nos canais preferidos pelos seus clientes. Estabeleça processos de melhoria contínua a partir de curadoria e observação de resultados para garantir uma experiência excepcional.",
  },
  {
    title: "Automação de bot",
    subTitle: "Transforme o atendimento na melhor experiência",
    text: "Crie uma jornada perfeita para o cliente, começando com uma conversa cordial, sincera e encerrando de forma significativa, com total satisfação. Ao interagir com um novo visitante, colete informações de identificação, forneça recomendações de produtos, solicite feedback e muito mais. A nossa solução armazena todos os detalhes da interação, adicionando variáveis para buscas futuras para fornecer contexto aos seus agentes.",
  },
  {
    title: "WhatsApp",
    subTitle: "Comunicação rápida e segura",
    text: "O WhatsApp se tornou o aplicativo mais popular do país e o principal canal de comunicação entre os clientes e as empresas. Com a criptografia de ponta a ponta do WhatsApp, os clientes podem gravar e enviar mensagens de voz com segurança, enviar e receber textos, arquivos e diversos tipos de mídia, como vídeos, imagens e emojis com facilidade. Ao contrário do e-mail e do SMS, o WhatsApp tem altas taxas de abertura, permitindo que você os notifique sobre transações, confirmações e faturas sem perder o foco. Adicionalmente, tornou-se um canal muito útil para impulsionar as vendas.",
  },
  {
    title: "Facebook",
    subTitle: "Tenha uma conversa ativa e personalizada com seus clientes",
    text: "Elimine a possibilidade de dar aos seus clientes a oportunidade de expressar críticas em suas redes sociais. Em vez disso, esteja presente e cumprimente-os quando estiverem em sua página. O Facebook é uma ótima plataforma para nutrir, lembrar, vender e promover seus produtos. Com a nossa solução, você pode coletar feedback, atualizar seu painel CSAT e automatizar tudo.",
  },
  {
    title: "Web chat",
    subTitle: "Crie a melhor primeira impressão",
    text: "Os chatbots para websites são a melhor forma de criar uma ótima primeira impressão e proporcionar experiências agradáveis para os clientes. Ajude seus clientes quando eles estiverem enfrentando problemas. Use chatbots para responder perguntas frequentes, coletar números de telefone, comentários e mostrar recomendações de produtos e serviços. Compreenda a natureza das necessidades e oriente seus agentes/clientes para a melhor ação.",
  },
  {
    title: "No aplicativo",
    subTitle: "Estenda o suporte no aplicativo para resoluções rápidas",
    text: "É fácil para seus clientes fazer capturas de tela e registrar preocupações no aplicativo se estiverem enfrentando dificuldades com seu produto ou serviço. Dessa forma, você pode dar aos seus clientes a tranquilidade de saber que sempre estará disponível para ajudar, independentemente do que estão enfrentando. É o local ideal para lembrar os clientes sobre manutenção, renovação de assinatura, lembretes de pagamento, etc., de forma proativa e eficiente.",
  },
  {
    title: "Instagram",
    subTitle: "Crie suporte ao vivo escalável e automatizado",
    text: "Os clientes estão sempre explorando produtos e serviços no Instagram. Com o Instagram Messaging, seus consumidores podem enviar mensagens para empresas em seus perfis, lojas e stories. A integração API permite gerenciar e escalar as conversas. Além disso, você pode até mesmo alternar entre canais, caso seja necessário. Seus agentes têm acesso a todas as consultas e detalhes do cliente em uma única interface.",
  },
];

const carouselComponent = ref(null);

function next() {
  carouselComponent.value.next();
}

function prev() {
  carouselComponent.value.prev();
}
</script>

<style lang="scss" scoped>
:deep(.carousel__pagination) {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
}

:deep(.carousel__pagination-button::after) {
  border-radius: 50% !important;
  width: 8px;
  height: 8px;
}
.btn-carousel {
  border: none;
  padding-block: 4px;
}
.r-side {
  position: absolute;
  right: 24px;
  img {
    transform: rotate(180deg);
  }
}

.l-side {
  position: absolute;
  left: 0;
}
</style>
